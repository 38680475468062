import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { useNavigate } from 'react-router-dom';
import {
    AiFillGitlab,
    AiFillLinkedin,
} from "react-icons/ai";


function Home2() {

    const navigate = useNavigate();
    function goToProject() {
        navigate("/project");
    }

    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                            LET ME <span className="purple"> INTRODUCE </span> MYSELF
                        </h1>
                        <p className="home-about-body">
                            I am senior software engineer who loves learning new technologies,
                            enjoys taking on challenges, and values sharing knowledge with team members.
                            I have a passion for problem-solving, evident in my completion of 100+ offline problems on Codeforces.
                            Over the past six years, I have successfully completed numerous projects.
                            <br />
                            <br />
                            For project management, we utilize Azure DevOps, employing it for version control, requirements management,
                            project management, and testing in our application development process. I am also an author on Codecanyon,
                            with my developed applications running successfully in more than 10 countries.
                            <br />
                            <br />
                            I am currently employed as a Senior software Engineer at <a target="_blank" href="https://softrobotics.com.bd/" className="purple">Softrobotics bangladesh</a>
                            <br />
                            <br />
                            I am fluent in
                            <i>
                                <b className="purple"> PHP, Laravel, MySql, Javascript, jQuery, Vuejs, Bootstrap, Htm5, Css3</b>
                            </i>
                            <br />
                            My field of Interest's are building new &nbsp;
                            <i>
                                <b className="purple">Web Technologies and Products </b>
                            </i>
                            <br />
                            <br />
                            My Codecanyon :  <a target="_blank" className="purple" href="https://codecanyon.net/user/mamun2074/portfolio">Url</a>
                            <br />
                            My Codeforces :  <a target="_blank" className="purple" href="https://codeforces.com/profile/mahmud_pau">Url</a>
                            <br />
                            Project : <a className="purple" href="#" onClick={goToProject}>Url</a>
                            <br />
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={myImg} className="img-fluid" alt="avatar" />
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1>FIND ME ON</h1>
                        <p>
                            Feel free to <span className="purple">connect </span>with me
                        </p>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                                <a
                                    href="https://gitlab.com/mamun2074"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                >
                                    <AiFillGitlab />
                                </a>
                            </li>
                            <li className="social-icons">
                                <a
                                    href="https://www.linkedin.com/in/mahmud2074/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                >
                                    <AiFillLinkedin />

                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
export default Home2;
