import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    DiJavascript1,
    DiLaravel,
    DiPhp,
    DiJqueryLogo,
    DiMysql,
    DiRedis,
    DiDotnet
} from "react-icons/di";
import { FaBootstrap, FaCss3, FaHtml5, FaSass, FaVuejs } from "react-icons/fa";

function Techstack() {
    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={4} md={2} className="tech-icons">
                <DiPhp />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiLaravel />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiMysql />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiRedis />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiDotnet />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiJavascript1 />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiJqueryLogo />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <FaVuejs />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <FaHtml5 />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <FaCss3 />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <FaBootstrap />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <FaSass />
            </Col>
        </Row>
    );
}

export default Techstack;
